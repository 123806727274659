import { Flex, Link as SpectrumLink, Tooltip, TooltipTrigger } from '@adobe/react-spectrum';

import ProductHuntLogo from './ProductHuntLogo';

const FooterLinks = () => (
  <Flex>
    <TooltipTrigger delay={0}>
      <SpectrumLink UNSAFE_className="link">
        <a
          aria-label="Product Hunt"
          href="https://www.producthunt.com/posts/comics-jobs"
          rel="noreferrer"
          target="_blank"
        >
          <ProductHuntLogo />
        </a>
      </SpectrumLink>
      <Tooltip>
        {'Product Hunt'}
      </Tooltip>
    </TooltipTrigger>
  </Flex>
);

export default FooterLinks;
